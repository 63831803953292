








































import { Component, Vue, Inject } from 'vue-property-decorator'
import { Observer } from 'mobx-vue'
import { ProjectDetailViewModel } from '../viewmodels/project-detail-viewmodel'

@Observer
@Component({
  components: {
    LabelStatus: () => import('../components/label-status.vue'),
  },
})
export default class ProjectDetailHeader extends Vue {
  @Inject() vm!: ProjectDetailViewModel
}
